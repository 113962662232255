<template>
  <div class="school-choice">
    <div class="bg"></div>
    <div class="bd">
      <div class="title">选择分局</div>
      <div class="select">
       <a-select
          v-model="selectedIndex"
          style="width: 100%;"
        >
          <a-select-option v-for="(item,index) in schools" :key="item._id" :value="index">
            {{item.name}}
          </a-select-option>
        </a-select>
      </div>
      <div class="button">
        <a-button
          type="primary"
          size="large"
          @click="onOk"
          autoFocus="true"
        >
          确认
        </a-button>
      </div>
    </div>
    <footer-component></footer-component>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import footerComponent from '@/components/Footer'
import apis from '@/business/apis'
export default {
  components: {
    footerComponent
  },
  data () {
    return {
      schools: [],
      selectedIndex: undefined
    }
  },
  computed: {
    ...mapState('school', [
      'currentOrg'
    ])
  },
  methods: {
    ...mapActions('school', [
      'setCurrSchool'
    ]),
    async loadList () {
      // const query = '?org_id=' + this.currentOrg._id
      // const response = await this.$http.get(apis.school.listByOrgId + query)
      const response = await this.$http.get(apis.orgAdmin.schoolList)
      const result = response.body
      this.schools = result.list
      this.selectedIndex = this.schools[0] ? 0 : undefined
      if (this.schools.length === 1) {
        this.onOk()
      }
    },
    async onOk () {
      if (this.selectedIndex === undefined) {
        this.$message.info('还没有选择分局')
        return
      }
      this.setCurrSchool(this.schools[this.selectedIndex])
      const id = this.schools[this.selectedIndex]._id
      const response = await this.$http.get(apis.studentGroup.count + id + '/count')
      // if (response.body.count) {
      //   this.$router.push({ name: 'FileManage' })
      // } else {
      //   this.$router.push({ name: 'ClassConfig' })
      // }
      if (!response.body.count) {
        this.$router.push({ name: 'ClassConfig' })
        return
      }
      const result = await this.$http.get(apis.user.count + '?schoolId=' + id)
      if (!result.body.count) {
        this.$router.push({ name: 'FileManage' })
        return
      }
      this.$router.push({ name: 'NutritionMonitorIndex' })
    }
  },
  created () {
    this.loadList()
  }
}
</script>

<style lang="less">
.school-choice {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  > .bg {
    position: fixed;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f2f2f2;
    background: url(../assets/images/bg.jpg);
    background-size: cover;
    background-position: center;
  }
  .bd {
    margin-bottom: 60px;
    width: 480px;
    background-color: #fff;
    box-shadow: 0 0 28px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 60px;
    text-align: center;
    .title {
      font-weight: bold;
      font-size: 20px;
    }
    .select {
      margin: 40px 0;
      .ant-select-selection--single {
        height: 48px;
      }
      .ant-select-selection__rendered {
        line-height: 46px;
      }
    }
    .button {
      .ant-btn {
        width: 120px;
        height: 52px;
        font-size: 16px;
        border: none;
      }
    }
  }
  .footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
